/*mobile view */
@media only screen and (max-width: 480px) {
    body {
      background-image: url("mobile-view.png");
    }
   .evvpLogo {
    display: flex;
    justify-content: center;
    align-items: center;
   }
   .loginBox{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 80%;
    background-color: 'rgb(46,43,43,0.49)';
    border-radius: 16px;

   }
}

.buttonText{
  color: rgba(35, 21, 21, 1);
}

.MuiButton-root:hover {
  background-color: #ff5733 !important; /* Change to your desired hover color */
}
* {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-align: center;
}

/* web view */
@media only screen
  and (min-width: 481px) {
    body {
        background-image: url('EVVP_TL_BG.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100vh;
        width: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
}
.container{
    margin-top: 50px;
}
.heading{
    font-size: 3em;
}
.form{
    margin:auto;
    width: 80%;
}
.camera{
    margin-top:20px;
}
.recipes-container
{
    padding: 10px;
}
/* Custom CSS for date number colors */
.rdrCalendarWrapper {
   background-color: transparent !important;;
}
.rdrDateRangeWrapper{
    width: 240px;
    background-color: transparent !important;;
}
.rdrDateRangeWrapper .rdrDays{
    background-color: transparent;
}
.rdrDateRangeWrapper .rdrDays .rdrStartEdge{
  background-color: #AD282D;
}
.rdrDateRangeWrapper .rdrDays .rdrInRange{
  background-color: #AD282D;
}
.rdrDateRangeWrapper .rdrDays .rdrEndEdge{
  background-color: #AD282D;
}
.rdrDateRangeWrapper .rdrMonth{
  background-color: transparent !important;;
    width: 240px;
    padding: 0%;
}
.rdrDateRangeWrapper .rdrMonthAndYearWrapper{
    background-color: #AD282D;
    color: #fbfafa;
}

.rdrDateRangeWrapper .rdrMonthAndYearWrapper{
    background-color: #AD282D;
    color: #fbfafa;
}
.rdrCalendarWrapper {
    background-color: transparent;
}


.rdrDateRangeWrapper .rdrDayWeekend .rdrDayNumber span{
    color: #AD282D
}

.rdrDateRangeWrapper .rdrDayNumber {
    box-shadow: 0 0 0 1px rgba(171, 171, 171, 1);
    top: 0;
    bottom: 0;
    margin: 0%;
    padding: 0%;
  }

.rdrDateRangeWrapper .rdrDayNumber span {
    color: #999999; /* Change text color of the nested <span> */
    font-weight: bold;
    
  }

.rdrDateRangeWrapper .rdrWeekDays{
    background-color: #E6E6E6;
    top: 0;
    bottom: 0;
    margin: 0%;
    padding: 0%;
   }

.rdrDateRangeWrapper .rdrWeekDays span {
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
  }
  
.rdrDateRangeWrapper .rdrNextPrevButton{

background-color: transparent;
color: white;
}
.rdrDateRangeWrapper .rdrNextPrevButton i{ 
  background-color: transparent;
  color: white;
  }

.loading-container {
    width: 241px;
    height: 338px;
    position: fixed;
    top: 50%;
    left: 50%;
    border-radius: 16px;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.49);
    z-index: 4001;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
  .loading-circle {
    width: 178px;
    height: 178px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .loading-text {
    height: 50px;
    position: absolute;
    top: 100%;
    width: 100%;
    text-align: center;
  }
  
  .loading-text-group {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: -0.4px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    transform: translate3d(-50%, -50%, 0);
  }

  .loading-text-item {
    position: absolute;
    width: 100%;
    opacity: 0;
    left: 0;
    top: 0;
  }
  
  .loading-stat-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .loading-stat-wrapper h4 {
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    margin-top: 47%;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .loading-stat-data {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-top: 50%;
  }
  
  .loading-stat-data strong {
    font-size: 46px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .loading-stat-data span {
    font-size: 30px;
    color: rgba(255, 255, 255, 0.8);
    margin-left: 2px;
  }
  
  .lds-ring {
    shape-rendering: auto;
    background: none;
  }
  
  .load {
    position: relative;
    margin: 50px auto;
    width: 100%;
    height: 100%;
  }
  
  .gear {
    position: absolute;
    z-index: -10;
    width: 40px;
    height: 40px;
    animation: spin 5s infinite;
  }
  
  .two {
    left: 40px;
    width: 80px;
    height: 80px;
    animation: spin-reverse 5s infinite;
  }
  
  .three {
    top: 45px;
    left: -10px;
    width: 60px;
    height: 60px;
  }
  
  @keyframes spin {
    50% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes spin-reverse {
    50% {
      transform: rotate(-360deg);
    }
  }
  .lil-circle {
    position: absolute;
    border-radius: 50%;
    box-shadow: inset 0 0 10px 2px gray, 0 0 50px white;
    width: 100px;
    height: 100px;
    opacity: .65;
  }
  
  .blur-circle {
    position: absolute;
    top: -19px;
    left: -19px;
  }
  
  .text {
    color: lightgray;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
  }
  
  @import url('https://fonts.googleapis.com/css?family=Open+Sans');
  